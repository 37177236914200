.sub-header-profile {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	height: 270px;
	padding: 6.3vh 12.44vw 7.22vh 12.44vw;
	margin-bottom: 48px;
	background: $color-6;

	.username {
		font-size: $font-size-xxmd;
		color: $color-1;
		font-weight: 500;
	}

	.ant-avatar.ant-avatar-icon > .anticon {
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;
	}

	@include breakPoint(small) {
		padding: 6.3vh 10vw 7.22vh 10vw;

		.ant-avatar {
			height: 90px !important;
			width: 90px !important;
		}

		.ant-avatar.ant-avatar-icon > .anticon {
			font-size: 40px !important;
		}

		.ant-space-vertical {
			gap: 15px !important;
		}

		.username {
			font-size: $font-size-sm;
		}

		.campaigns-info-wrapper {
			.info-box {
				padding: 10px 12.5px !important;

				.data {
					font-size: $font-size-xxsm;
				}
			}
		}
	}
}

.campaigns-tab-wrapper {
	margin: 0 6.9vw;
	min-height: 25vh;

	.ant-tabs-content-holder {
		margin: 0 5.96vw;

		@include breakPoint(small) {
			margin: 0 3.96vw;

			.ant-tabs-tabpane {
				width: 100%;
				display: flex;
				justify-content: center;
			}
		}
	}

	.ant-tabs-content {
		display: inline;
	}

	.ant-tabs-tab-btn {
		color: $color-text-2;
	}

	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: $color-text-2;
		font-weight: 500;
	}

	.ant-tabs-tab-btn:focus,
	.ant-tabs-tab-btn:active,
	.ant-tabs-tab-btn:hover {
		color: $color-text-2;
	}

	.ant-tabs-tab:hover {
		color: $color-text-2;
	}

	.ant-tabs-ink-bar {
		background: $color-1;
	}

	.options-wrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 3.3vh;

		.selector-wrapper {
			background: $color-2;
			border-radius: $border-radius-wrapper;
			display: flex;
			justify-content: center;
		}

		.dropdown-wrapper {
			background: $color-2;
		}

		// Search bar
		$tl: 0.5s; // transition length

		.search-box {
			position: absolute;
			transition: width $tl, border-radius $tl, background $tl, box-shadow $tl;
			width: 100%;
			height: 100%;
			border-radius: $border-radius-btn;
			border: $primary-border;
			padding-left: 35px;
			cursor: pointer;
			background: transparent;
			& + label .search-icon {
				color: $color-text-5;
			}
			&:hover {
				color: $color-text-2;
				border: $primary-border-hover;
				& + label .search-icon {
					color: $color-text-2;
				}
			}
			&:focus {
				border: $primary-border-hover;
				outline: 2px solid $color-3;
				box-shadow: none;
				font-weight: 400;
				color: $color-text-1;
				cursor: text;
				& + label .search-icon {
					color: $color-text-2;
				}
			}

			@include breakPoint(small) {
				width: 30px;
				&:focus {
					transition: width $tl cubic-bezier(0, 1.22, 0.66, 1.39), border-radius $tl,
						background $tl;
					border: none;
					outline: 2px solid $color-3;
					box-shadow: none;
					padding-left: 35px;
					cursor: text;
					width: 44vw;
					border-radius: auto;
					background: $color-3;
					color: $color-text-1;
					& + label .search-icon {
						color: $color-text-5;
					}
				}
				&:not(:focus) {
					text-indent: -200px;
				}
			}
		}

		::-webkit-input-placeholder {
			color: $color-text-2;
			font-weight: 400;
			font-size: $font-size-xsm;

			@include breakPoint(small) {
				font-size: $font-size-xxsm;
			}
		}

		#search-submit {
			display: none;
		}

		.search-icon {
			position: relative;
			top: 9px;
			color: $hover-color;
			margin: 0 10px;
			font-size: $font-size-sm;
			color: $color-text-5;
			cursor: pointer;
			&:hover {
				color: $hover-color;
			}
		}

		.search-box-wrapper {
			width: 100%;
			display: flex;
			justify-content: center;
		}

		.input-search {
			border-radius: $border-radius-btn;
			height: 100%;
			border: 0;
			margin: 0 20px;
			position: relative;
			z-index: 10;
			width: 100%;

			@include breakPoint(small) {
				width: auto;
			}
		}
	}
}

@include breakPoint(small) {
	.ant-tabs-tab-btn {
		font-size: $font-size-xsm;
	}
}

.tertiary-btn {
	min-width: 122px;
	font-weight: 400 !important;
	margin: 12px;
	padding: 4px 8px;
	&:first-child {
		margin-bottom: 0;
	}
	&:hover {
		border-color: $color-1;
	}

	@include breakPoint(small) {
		min-width: 85px;
	}
}

.tertiary-btn-dropdown {
	@extend .tertiary-btn;
	margin: 0;
	min-width: 213px;
	padding: 0 12px;
	justify-content: space-between !important;
	&:hover {
		border-color: $color-1;
		color: $color-1;
	}

	svg {
		fill: $color-4;
	}

	@include breakPoint(small) {
		min-width: 110px;
	}
}

.dropdown-wrapper {
	border-radius: $border-radius-wrapper;

	.item {
		font-size: $font-size-xxsm !important;
		font-weight: 400;
	}
}

// AntD selector & dropdown styles
.ant-select-dropdown {
	border-radius: $border-radius-wrapper;

	@include breakPoint(small) {
		min-width: 110px;
	}
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	min-width: 145px;
	text-align: center;
	border-radius: $border-radius-wrapper;
	background: $color-2;
	height: 32px;

	@include breakPoint(small) {
		min-width: 110px;
	}
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
	font-size: $font-size-xsm;

	@include breakPoint(small) {
		font-size: $font-size-xxsm;
	}
}

.ant-select-single.ant-select-open .ant-select-selection-item {
	color: $color-text-3;
	background: $color-1;
	border-radius: $border-radius-wrapper;
	border-color: $color-4;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
	.ant-select-selector {
	border-color: $color-4;
	box-shadow: none;
	padding: 0;
	transition: all 0s;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	color: $color-1;
	background: $color-2;
	font-weight: 400;
	border-radius: $border-radius-wrapper;
}

@include breakPoint(small) {
	.ant-tabs-tab + .ant-tabs-tab {
		margin: 0 32px 0 0;
	}
}

.ant-select-item-option-content {
	font-weight: 400;
	font-size: $font-size-xsm;

	@include breakPoint(small) {
		font-size: $font-size-xxsm;
	}
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	background: $color-1;
	color: $color-text-3;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	color: $color-text-3;
	background-color: $color-1;
	border-color: $color-1;
}

.ant-select-selector {
	font-size: $font-size-xsm !important;

	@include breakPoint(small) {
		font-size: $font-size-xxsm;
	}

	span {
		font-size: $font-size-xsm !important;

		@include breakPoint(small) {
			font-size: $font-size-xxsm;
		}
	}
}

.ant-select-item-option {
	align-items: center;
}
