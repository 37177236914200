.campaign-box-wrapper {
	width: 100%;
	height: fit-content;
	padding: 24px 32px;
	margin: 3.3vh 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border: $primary-border;
	border-radius: $border-radius-wrapper;
	background: $color-2;

	&:last-child {
		margin-bottom: 0;
	}

	&:hover {
		box-shadow: inset 0px 0px 0px 1px $color-4;
	}

	.campaign-header-wrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.collection-name {
			color: $color-text-2;
			font-size: $font-size-md;
		}

		.client-name {
			color: $color-text-4;
			font-size: $font-size-xxsm;
		}

		.collection-info-wrapper {
			cursor: pointer;
		}

		.primary-btn {
			max-width: 105px;
			min-height: 44px;
			font-size: $font-size-xsm;
		}

		.date-wrapper {
			width: 100%;
			min-height: 44px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: $color-6;
			border-radius: $border-radius-wrapper;
			padding: 12px 14px;

			span {
				font-size: $font-size-xsm;
			}

			.info {
				color: $color-text-4;
			}

			.date {
				color: $color-text-1;
				background: transparent;
			}
		}

		.collected-wrapper {
			@extend .date-wrapper;
			background: transparent;
			color: $color-text-1;
			font-size: $font-size-xsm;
			justify-content: center !important;
			max-width: 120px;
			min-height: 44px;
		}
	}

	.content-wrapper {
		display: flex;
		flex-wrap: nowrap;
		overflow: hidden;
		margin-top: 3.3vh;

		.item-name {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;
			background: rgba(0, 0, 0, 0.5);
			visibility: hidden;
			border-radius: $border-radius-card;
			color: $color-text-3;
			padding: 20px 16px;
		}

		.item-wrapper {
			width: 218px;
			margin-right: 20px;
			flex: 0 0 auto;
			position: relative;

			&:hover .item-name {
				visibility: visible;
			}

			img {
				width: 100%;
				border-radius: $border-radius-card;
			}
		}

		.item-wrapper-claimed {
			@extend .item-wrapper;

			img {
				filter: grayscale(100%);
			}
		}
	}

	@include breakPoint(small) {
		padding: 18px 22px;

		.campaign-header-wrapper {
			flex-direction: column;
			align-items: stretch;
			gap: 20px;
			position: relative;

			.collection-name {
				font-size: $font-size-xsm !important;
			}

			.client-name {
				font-size: $font-size-xxsm;
			}

			.primary-btn {
				font-size: $font-size-xxsm !important;
			}

			.date-wrapper {
				margin-left: 10px;
				justify-content: center;

				span {
					font-size: $font-size-xxsm !important;
				}
			}

			.info-sub {
				gap: 0 !important;
			}

			.collected-wrapper {
				font-size: $font-size-xxsm !important;
				position: absolute;
				top: 0;
				right: 0;
				padding-left: 80px !important;
				text-align: center;
			}
		}

		.content-wrapper {
			.item-wrapper {
				width: 118px;
				margin-right: 15px;
			}
		}
	}
}
