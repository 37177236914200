.login-wrapper {
	height: calc(100vh - 73px);
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: rgba(73, 73, 73, 0.4);
	backdrop-filter: blur(4px);

	@include breakPoint(small) {
		padding: 0 10vw;
	}

	.tertiary-btn {
		border: none;
		align-self: center;
		margin: 15px 0 0 0;
		&:hover {
			background: transparent;
			color: $color-text-5;
		}
	}
}

.login-box {
	height: fit-content;
	width: 100%;
	max-width: 480px;
	padding: 16px 0;
	background: $color-2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border: $primary-border;
	border-radius: $border-radius-card;
	padding: 24px 32px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

	.header {
		font-size: $font-size-xxmd;
		color: $color-text-2;
		font-weight: 700;
		margin-bottom: 3.3vh;
	}

	.primary-btn {
		width: 100%;
		margin-top: 4.7vh;
	}

	.google-btn {
		@extend .primary-btn;
		margin-top: 20px;
		background: $color-8;
		&:hover {
			background: $hover-color-2;
		}
	}

	input {
		border-radius: $border-radius-input;
	}

	.ant-input-affix-wrapper {
		border-radius: $border-radius-input;
	}

	label {
		font-weight: 700 !important;
		color: $color-text-2;
	}

	.ant-form-vertical .ant-form-item-label {
		padding: 0;
	}

	@include breakPoint(small) {
		.header {
			font-size: $font-size-md;
		}

		.ant-form-item {
			margin-bottom: 2.4vh;
		}

		input {
			@include breakPoint(small) {
				font-size: $font-size-xxsm;
				::-webkit-input-placeholder {
					font-size: $font-size-xxsm;
				}
			}
		}

		label {
			font-size: $font-size-xxsm;
		}
	}
}

.register-box {
	@extend .login-box;

	.header {
		width: 100%;
		display: flex;
		align-self: flex-start;
		margin-bottom: 49px;
	}

	.secondary-btn {
		width: 100%;
		min-width: 239px;
		height: 56px;
		font-weight: 400;
		display: flex;
		justify-content: flex-start;
		padding: 16px 32px 16px 16px;
		border-radius: $border-radius-wrapper;

		svg {
			margin-right: 10px;
		}
	}

	.sign-in-tos {
		text-align: center;

		span {
			font-weight: 400;
			color: $color-text-2;
			font-size: $font-size-xxsm;
		}
	}
}

.register-footer {
	@extend .register-box;
	margin-top: 16px;
	cursor: pointer;

	span {
		font-weight: 400;
		text-align: center;
		&:hover {
			color: $hover-color;
		}
	}

	@include breakPoint(small) {
		span {
			font-size: $font-size-xsm;
		}
	}
}
