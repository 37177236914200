@import './helpers/globals';

// components
@import './components/layout';
@import './components/button';
@import './components/campaign-card';
@import './components/campaign-box';
@import './components/nft-card';
@import './components/login';
@import './components/confirm-modal';

// pages
@import './pages/home';
@import './pages/claim';
@import './pages/item';
@import './pages/profile';
@import './pages/campaign';

html {
	height: 100%;
	width: 100%;
}

body {
	margin: 0;
	padding: 0;
	overflow-x: hidden !important;
	height: 100%;
	width: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

div,
span,
p,
label,
input,
select,
section,
footer,
header,
nav,
ul,
li,
button,
tr,
td,
th {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
}

label {
	font-weight: 500;
}

* {
	box-sizing: border-box;
	-webkit-overflow-scrolling: touch;
	scroll-behavior: smooth;
}

#root {
	min-height: 100vh;
}

.hidden {
	display: none !important;
}

.no-scroll {
	height: 100%;
	overflow: hidden;
}

button {
	outline: none !important;
}

.page-wrapper {
	width: 100%;
	overflow: scroll;
}

#root {
	overflow-x: hidden;
}

.main-section {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	overflow: scroll;
	.content {
		width: 100%;
	}
}

.ant-spin-dot-item {
	background-color: $color-4;
}

.bold {
	font-weight: 700;
}

.cut-text {
	text-overflow: ellipsis;
	overflow: hidden;
	width: 240px;
	white-space: nowrap;
	display: inline-block;
}

.ant-modal-footer {
	border-top: 0;
}
