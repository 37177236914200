.nft-view-wrapper {
	background: $color-2;
	margin: 8.4vh 18.5vw 0 18.5vw;
	display: flex;
	justify-content: center;
	gap: 70px;

	@include breakPoint(small) {
		flex-direction: column;
		align-items: center;
		margin: 5.4vh 10vw 0 10vw;
		gap: 50px;
	}

	.nft-wrapper {
		display: flex;
		flex-direction: column;
		max-width: 488px;

		.nft-box-wrapper {
			margin-bottom: 4.33vh;

			img {
				width: 100%;
				max-width: 488px;
			}
		}

		.title {
			font-size: $font-size-title;
			color: $color-text-1;
		}

		.description {
			color: $color-1;
			font-weight: 400;
			line-height: 24px;
		}

		@include breakPoint(small) {
			.title {
				font-size: $font-size-md;
			}

			.description {
				font-size: $font-size-xsm;
			}
		}
	}

	.nft-content-wrapper {
		display: flex;
		flex-direction: column;
		width: fit-content;

		.title {
			font-size: $font-size-xxmd;
			color: $color-text-1;
			font-weight: 500;
			margin-bottom: 25px;
		}

		.title-sm {
			color: $color-text-1;
			font-size: $font-size-md;
			line-height: 24px;
		}

		.owner {
			font-size: $font-size-xxsm;
			color: $color-text-4;
		}

		.info {
			margin-top: 3.3vh;

			&:nth-child(4) {
				margin: 1.65vh 0 3.3vh 0;
			}

			.label {
				font-size: $font-size-xxsm;
				color: $color-text-4;
			}

			.ant-space-item {
				width: 100%;
			}

			.box {
				border-radius: $border-radius-wrapper;
				background: $color-text-6;
				padding: 22px 0;
				text-align: center;

				.data {
					font-weight: 500;
					font-size: $font-size-xmd;
					color: $color-text-1;
				}
			}
		}

		button {
			width: 100%;
		}

		.btns-wrapper {
			margin-bottom: 32px;
		}

		// SubMenu Antd styles
		.ant-menu-inline {
			border-radius: $border-radius-wrapper;

			.ant-menu-title-content {
				display: flex;
				justify-content: space-between;

				.label {
					color: $color-text-4;
				}

				.data {
					color: $color-1;
				}
			}

			&.ant-menu-root {
				border: $primary-border;

				.ant-menu-submenu-title {
					padding: 0 0 0 20px !important;
					color: $color-1;
				}
			}

			.ant-menu-item::after {
				border: 0;
			}
		}

		.ant-menu-sub.ant-menu-inline {
			border-radius: 0 0 $border-radius-wrapper $border-radius-wrapper;
			border-top: $primary-border;
			background: $color-7 !important;

			.ant-menu-item {
				padding-left: 20px !important;
			}
		}

		.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
			background: none;
		}

		.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
			&:before {
				background: $color-4;
			}

			&:after {
				background: $color-4;
			}
		}

		@include breakPoint(small) {
			width: 100%;

			.title {
				font-size: $font-size-xmd;
			}

			.title-sm {
				font-size: $font-size-sm;
			}

			.data {
				font-size: $font-size-md !important;
			}

			.ant-avatar {
				height: 60px !important;
				width: 60px !important;
			}

			button {
				padding: 12px 32px;
			}

			.ant-menu-title-content {
				font-size: $font-size-xsm;

				.label {
					font-size: $font-size-xsm !important;
				}

				.data {
					font-size: $font-size-xsm !important;
				}
			}
		}
	}
}
