.sub-header {
	display: flex;
	justify-content: space-evenly;
	width: 100%;
	height: fit-content;
	padding: 13.6vh 20vw 8.2vh 20vw;
	margin-bottom: 100px;
	background: $color-3;
	gap: 5vw;

	@include breakPoint(small) {
		padding: 10.6vh 10vw 8.2vh 10vw;
		flex-direction: column;
		align-items: center;
		gap: 0;
	}

	.content {
		margin-top: 4.2vh;
		max-width: 467px;

		.title {
			font-size: $font-size-xlg;
			color: $color-text-1;
			line-height: 60px;
		}

		.description {
			font-weight: 400;
			color: $color-text-1;
		}

		button {
			width: 145px;
		}

		@include breakPoint(small) {
			gap: 20px !important;

			.title {
				font-size: $font-size-lg;
				line-height: 4.2vh;
			}

			.description {
				font-size: $font-size-xsm;
			}

			button {
				margin-bottom: 5.2vh;
			}
		}
	}

	.card-wrapper {
		width: 326px;
		height: 419px;
		margin-right: 0;

		.info-wrapper {
			margin-top: 50px;

			.collection-name {
				font-size: $font-size-lg;
			}

			.client-name {
				span {
					font-size: $font-size-md;
				}
			}
		}

		.date-wrapper {
			span {
				font-size: $font-size-xmd;
			}
		}

		@include breakPoint(small) {
			width: 291px;
			height: 380px;

			.ant-avatar {
				height: 80px !important;
				width: 80px !important;
			}

			.info-wrapper {
				margin: 45px 0 10px 0;

				.collection-name {
					font-size: $font-size-xmd;
				}

				.client-name {
					span {
						font-size: $font-size-xsm;
					}
				}
			}

			.date-wrapper {
				span {
					font-size: $font-size-sm;
				}
			}
		}
	}
}

.campaign-wrapper {
	margin: 0 10vw;

	@include breakPoint(small) {
		.title {
			font-size: $font-size-xmd !important;
		}
	}

	.cards-wrapper {
		min-height: 263px;
		padding-bottom: 6.9vh;

		.card-wrapper {
			@include breakPoint(small) {
				width: 191px;
				height: 248px;

				.ant-avatar {
					height: 60px !important;
					width: 60px !important;
				}

				.info-wrapper {
					margin-top: 30px !important;

					.collection-name {
						font-size: $font-size-sm !important;
					}

					.client-name {
						span {
							font-size: $font-size-xxsm !important;
						}
					}
				}

				.date-wrapper {
					span {
						font-size: $font-size-xxsm !important;
					}
				}
			}
		}

		.title {
			font-size: $font-size-lg;
			color: $color-text-1;
		}

		.scrolling-wrapper {
			display: flex;
			flex-wrap: nowrap;
			overflow-x: scroll;
			overflow-y: hidden;
			min-height: 263px;
			padding: 0 5px;
			scroll-behavior: smooth;

			.card {
				flex: 0 0 auto;
				margin: 34px 0;
			}
		}

		&:nth-child(2) {
			padding-bottom: 0;
		}
	}
}
