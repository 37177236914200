.card {
	display: flex;
}

.nft-card {
	margin-top: 3.3vh;

	.card-wrapper {
		position: relative;
		margin: 0;
		cursor: default;

		&:hover {
			box-shadow: unset;
			transition: unset;
		}

		.date-wrapper-nft {
			width: fit-content;
			height: 26px;
			background: $color-6;
			border-radius: $border-radius-wrapper;
			padding: 0 14px;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
			margin: 8px 7px;

			div {
				position: relative;
				top: 15%;
			}

			span {
				font-size: $font-size-xsm;
				position: relative;
				top: 15%;
			}

			.info {
				color: $color-text-4;
			}

			.date {
				color: $color-text-1;
				background: transparent;
			}
		}

		.img-wrapper {
			height: 70%;
			overflow: hidden;
			border-radius: $border-radius-card $border-radius-card 0 0;

			img {
				position: relative;
				top: -15px;
				width: 100%;
			}
		}

		.info-wrapper {
			align-items: flex-start;
			margin: 18px 14px;

			.campaign-name {
				font-size: $font-size-xxsm;
				color: $color-text-5;
			}
		}
	}

	.card-wrapper-claimed {
		@extend .card-wrapper;
		filter: grayscale(100%);
		pointer-events: none;
	}

	@include breakPoint(small) {
		.card-wrapper {
			width: 231px;
			height: 298px;

			.date-wrapper-nft {
				height: fit-content;
				padding: 4px 8px;

				.ant-space {
					gap: 4px !important;
				}

				span {
					font-size: $font-size-xxsm !important;
				}
			}

			.info-wrapper {
				margin: 16px 14px;

				.item-name {
					font-size: $font-size-xsm;
				}
			}
		}
	}
}
