.header-wrapper {
	height: 73px;
	width: 100%;
	padding: 17px 10vw;
	background: $color-2;
	display: flex;
	justify-content: space-between;
	align-items: center;
	top: 0;
	position: sticky;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	z-index: 10;

	a {
		text-decoration: none;
		color: $color-text-1;

		&:hover {
			color: $hover-color;
		}
	}

	.title {
		font-size: $font-size-title;
		font-weight: 700 !important;
	}

	.ant-avatar.ant-avatar-icon > .anticon {
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;
	}

	.ant-avatar {
		height: 40px;
		width: 40px;
		margin-left: 20px;
	}

	.options-wrapper {
		display: flex;
		align-items: center;
		a {
			white-space: nowrap;
			&:nth-child(2) {
				margin-left: 20px;
			}
		}
	}

	@include breakPoint(small) {
		padding: 18.5px 10vw;

		.title {
			font-size: $font-size-xsm;
		}

		.ant-avatar {
			height: 37px !important;
			width: 37px !important;
			margin-left: 10px;
		}

		.options-wrapper {
			a {
				font-size: $font-size-xsm !important;
				&:nth-child(2) {
					margin-left: 10px;
				}
			}
		}
	}

	// Search bar
	$tl: 0.5s; // transition length

	.search-box-wrapper {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.input-search {
		border-radius: $border-radius-btn;
		height: 100%;
		border: 0;
		margin: 0 20px;
		position: relative;
		z-index: 10;
		width: 25vw;

		@include breakPoint(small) {
			width: auto;
		}
	}

	.search-box {
		position: absolute;
		transition: width $tl, border-radius $tl, background $tl, box-shadow $tl;
		width: 25vw;
		height: 100%;
		border-radius: $border-radius-btn;
		border: $primary-border;
		padding-left: 35px;
		cursor: pointer;
		background: transparent;
		& + label .search-icon {
			color: $color-text-5;
		}
		&:hover {
			color: $color-text-2;
			border: $primary-border-hover;
			& + label .search-icon {
				color: $color-text-2;
			}
		}
		&:focus {
			border: $primary-border-hover;
			outline: 2px solid $color-3;
			box-shadow: none;
			font-weight: 400;
			color: $color-text-1;
			cursor: text;
			& + label .search-icon {
				color: $color-text-2;
			}
		}

		@include breakPoint(small) {
			width: 30px;
			&:focus {
				transition: width $tl cubic-bezier(0, 1.22, 0.66, 1.39), border-radius $tl,
					background $tl;
				border: none;
				outline: 2px solid $color-3;
				box-shadow: none;
				padding-left: 35px;
				cursor: text;
				width: 50vw;
				border-radius: auto;
				background: $color-3;
				color: $color-text-1;
				& + label .search-icon {
					color: $color-text-5;
				}
			}
			&:not(:focus) {
				text-indent: -200px;
			}
		}
	}

	::-webkit-input-placeholder {
		color: $color-text-2;
		font-weight: 400;
		font-size: $font-size-xsm;

		@include breakPoint(small) {
			font-size: $font-size-xxsm;
		}
	}

	#search-submit {
		display: none;
	}

	.search-icon {
		position: relative;
		top: 11px;
		color: $hover-color;
		margin: 0 10px;
		font-size: $font-size-sm;
		color: $color-text-5;
		cursor: pointer;
		&:hover {
			color: $hover-color;
		}
	}
}

.avatar-dropdown {
	cursor: pointer !important;
}

.avatar-menu {
	border-radius: $border-radius-wrapper;
	width: fit-content;
	min-width: 120px;

	.avatar-menu-item {
		padding: 10px;
		color: $color-text-2;
	}

	@include breakPoint(small) {
		min-width: 100px;
		span {
			font-size: $font-size-xsm;
		}
	}
}

.footer-wrapper {
	height: fit-content;
	width: 100%;
	display: flex;
	flex-direction: column;
	background: $color-3;
	padding: 40px 10vw 24px 10vw;
	margin-top: 15vh;

	@include breakPoint(small) {
		padding: 40px 10vw 24px 10vw;
		align-items: center;
	}

	.footer-content {
		width: 100%;
		display: flex;
		justify-content: space-between;

		@include breakPoint(small) {
			flex-direction: column;
			align-items: flex-start;

			.title {
				font-size: $font-size-xsm;
			}

			.ant-space {
				gap: 12px !important;
			}

			.social-wrapper {
				margin: 30px 0 50px 0;
			}

			.description-wrapper {
				max-width: 100vw !important;
			}

			.marketing-portal-wrapper {
				max-width: 100vw !important;
			}
		}

		.title {
			color: $color-text-1;
		}

		.description {
			color: $color-text-4;
			font-size: $font-size-xxsm;
		}

		.description-wrapper {
			max-width: 265px;
		}
	}

	.divider {
		border: $primary-border;
		margin: 16px 0;
		width: 100%;
	}

	.copyrights-wrapper {
		display: flex;
		justify-content: space-between;
		width: 100%;

		span {
			color: $color-text-4;
			font-size: $font-size-xsm;
		}

		a {
			text-decoration: none;
			color: $color-text-4;
			font-size: $font-size-xxsm;
			&:hover {
				color: $hover-color;
			}
		}

		@include breakPoint(small) {
			.ant-space {
				gap: 12px !important;
			}
		}
	}

	.social-wrapper {
		margin: 0 20px;
	}

	.marketing-portal-wrapper {
		max-width: 265px;
		position: relative;
		top: -15px;
		border: $primary-border;
		border-radius: $border-radius-wrapper;
		padding: 16px;
		&:hover {
			box-shadow: inset 0px 0px 0px 1.5px $color-4;
		}
	}
}
