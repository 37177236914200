.card-wrapper {
	width: 231px;
	height: 300px;
	background: $color-2;
	display: flex;
	flex-direction: column;
	margin-right: 24px;
	border: $primary-border;
	border-radius: $border-radius-card;
	cursor: pointer;
	position: relative;

	&:first-child {
		margin-left: 0;
	}

	&:hover {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
		transition: all 200ms ease;
	}

	.img-wrapper {
		height: 54%;
		overflow: hidden;
		border-radius: $border-radius-card $border-radius-card 0 0;

		img {
			position: relative;
			top: -15px;
			width: 100%;
		}
	}

	.campaign-avatar {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
	}

	.info-wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 30px;

		.collection-name {
			color: $color-text-2;
			font-size: $font-size-md;
		}

		.client-name {
			display: flex;
			justify-content: center;
			color: $color-text-4;

			span {
				font-size: $font-size-xxsm;
				font-weight: 700;
			}
		}
	}

	.date-wrapper {
		width: 100%;
		height: 15%;
		display: flex;
		justify-content: center;
		background: $color-6;
		border-radius: 0 0 $border-radius-card $border-radius-card;
		padding: 12px 0;
		position: absolute;
		bottom: 0;

		span {
			font-size: $font-size-xsm;
		}

		.info {
			color: $color-text-4;
		}

		.date {
			color: $color-text-1;
			background: transparent;
			position: relative;
		}
	}
}
