.sub-header-campaign {
	height: fit-content;
	max-height: 345px;
	background: $color-3;
	position: relative;
	overflow: hidden;

	.campaign-banner-image {
		object-fit: contain;
		width: 100%;
		max-height: 345px;
		position: relative;
		z-index: 2;
	}

	.banner-blur-bg {
		background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), center no-repeat;
		background-size: auto, cover;
		filter: blur(50px);
		width: 100%;
		position: absolute;
		top: -100%;
		z-index: 1;

		@include breakPoint(small) {
			top: 0;
		}
	}
}

.campaign-page-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	z-index: 3;

	.campaign-info-wrapper {
		display: flex;
		justify-content: center;
		margin: 3.9vh 18.9vw 6.4vh 18.9vw;

		.avatar-wrapper {
			transform: translateY(-50%);
			margin-right: 24px;
			width: 155px;
			min-width: 155px;
			box-shadow: $box-shadow;
		}

		.info-2 {
			width: 100%;
			max-width: 620px;
			display: flex;
			flex-direction: column;
			margin-bottom: 3.3vh;

			.info-inner {
				width: 100%;
				display: flex;
				justify-content: space-between;
				margin-bottom: 3.3vh;

				.collection-name {
					color: $color-text-2;
					font-size: $font-size-xxmd;
				}

				.client-name {
					color: $color-text-4;
					font-size: $font-size-md;
				}

				.date-wrapper {
					width: 100%;
					min-height: 44px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					background: $color-6;
					border-radius: $border-radius-wrapper;
					padding: 12px 14px;

					span {
						font-size: $font-size-xsm;
					}

					.info {
						color: $color-text-4;
					}

					.date {
						color: $color-text-1;
						background: transparent;
					}
				}
			}

			.description {
				font-weight: 400;
			}
		}

		@include breakPoint(small) {
			margin: 3.9vh 10vw 6.4vh 10vw;

			.avatar-wrapper {
				width: 90px !important;
				min-width: 90px !important;
			}

			.ant-avatar {
				height: 90px !important;
				width: 90px !important;
			}

			.info-2 {
				.info-inner {
					position: relative;

					.ant-space-vertical {
						gap: 7px !important;
					}

					.collection-name {
						font-size: $font-size-md !important;
					}

					.client-name {
						font-size: $font-size-xsm;
					}

					.date-wrapper {
						padding: 10px 8px;
						min-height: fit-content;
						position: absolute;
						width: fit-content;
						left: 0;
						top: 100%;
						margin-top: 10px;

						span {
							font-size: $font-size-xxsm;
						}
					}
				}

				.description {
					font-size: $font-size-xxsm;
					margin-top: 40px;
				}
			}
		}
	}

	.campaign-content-wrapper {
		margin: 0 12.8vh;
		width: 1000px; // fixed width so flex-wrap and header align on bigger screens

		.header {
			color: $color-text-2;
			font-size: $font-size-xmd;
		}

		.items-wrapper {
			display: flex;
			flex-wrap: wrap;
			column-gap: 24px;
			justify-content: flex-start;
		}

		@include breakPoint(small) {
			width: fit-content;
			margin: 0 8vh;

			.header {
				font-size: $font-size-md;
			}

			.items-wrapper {
				column-gap: 14px;
				justify-content: center;
			}
		}
	}
}
