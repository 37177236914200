.claim-nft-wrapper {
	background: $color-2;
	margin: 7.4vh auto 0 auto;
	display: flex;
	flex-direction: column;
	max-width: 555px;

	@include breakPoint(small) {
		margin: 7.4vh 10vw 0 10vw;

		.title {
			font-size: $font-size-xmd !important;
			margin-bottom: 4.9vh !important;
		}

		.description {
			font-size: $font-size-xsm !important;
		}

		.note {
			font-size: $font-size-xxsm !important;
		}

		.nft-wrapper {
			margin-bottom: 4.9vh !important;
		}

		.input-wrapper {
			margin-bottom: 3.1vh !important;

			.label {
				font-size: $font-size-xsm !important;
			}
		}

		.nft-wrapper-visible {
			margin-bottom: 4.9vh !important;

			.title {
				font-size: $font-size-xsm !important;
			}

			.description {
				font-size: $font-size-xxsm !important;
			}

			.ant-space-vertical {
				gap: 0 !important;
			}
		}
	}

	.title {
		font-size: $font-size-lg;
		color: $color-text-1;
		margin-bottom: 48px;
	}

	.nft-wrapper {
		margin-bottom: 48px;

		img {
			width: 100%;
		}
	}

	.input-wrapper {
		width: 100%;
		margin-bottom: 30px;

		input {
			border-radius: $border-radius-input;

			@include breakPoint(small) {
				font-size: $font-size-xsm;
				::-webkit-input-placeholder {
					font-size: $font-size-xxsm;
				}
			}
		}

		.label {
			color: $color-1;
			font-size: $font-size-md;
		}
	}

	button {
		align-self: center;
		width: 176px;
	}

	.nft-wrapper-visible {
		margin-bottom: 48px;
		position: relative;

		.info-wrapper {
			width: 100%;
			height: fit-content;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			background: $item-info-wrapper;
			border-radius: 0 0 $border-radius-card $border-radius-card;
			padding: 13px 16px;
			position: absolute;
			bottom: 0;

			.title {
				color: $color-1;
				font-size: $font-size-sm;
			}

			.description {
				font-weight: 400;
				font-size: $font-size-xsm;
				color: $color-text-5;
			}
		}

		img {
			width: 100%;
			border-radius: $border-radius-card;
		}
	}

	.description {
		color: $color-1;
		font-weight: 400;
		margin-bottom: 2.47vh;
		text-align: center;
		line-height: 24px;
	}

	.note {
		font-weight: 400;
		color: $color-1;
		font-size: $font-size-xsm;
		margin-top: 1.65vh;
		text-align: center;
	}
}

// NFT Claim Loader
.loader {
	$path: $color-1;
	width: 88px;
	height: 88px;
	position: relative;
	margin: 0 auto !important;
	&:before {
		content: '';
		width: 12px;
		height: 12px;
		border-radius: 50%;
	}
	svg {
		display: block;
		width: 100%;
		height: 100%;
		rect {
			fill: none;
			stroke: $path;
			stroke-width: 15px;
			stroke-linejoin: round;
			stroke-linecap: round;
		}
		rect {
			stroke-dasharray: (512 / 4 * 3) (512 / 4) (512 / 4 * 3) (512 / 4);
			stroke-dashoffset: 0;
			animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
		}
	}

	@keyframes pathRect {
		25% {
			stroke-dashoffset: 128;
		}
		50% {
			stroke-dashoffset: 256;
		}
		75% {
			stroke-dashoffset: 384;
		}
		100% {
			stroke-dashoffset: 512;
		}
	}
}
