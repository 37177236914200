.confirm-modal {
	.confirm-content-wrapper {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.confirm-info {
			color: $color-text-2;
			margin: 24px 0;
		}
	}

	.ant-modal-content {
		min-height: 240px !important;
		min-width: 480px !important;
		border-radius: $border-radius-wrapper !important;
	}

	.ant-modal-body {
		padding: 30px 0 20px 0;
	}

	.ant-modal-footer {
		margin: 0 auto;
	}

	.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
		margin: auto;
	}

	.spinner {
		position: absolute;
		top: 50%;
		margin-right: -50%;
		transform: translate(-50%, -50%);
		left: 50%;
	}
}
